import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import HeroChevron from "../../components/hero/hero-chevron";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";
import MktoForm from "../../components/mkto-form/mkto-form";
import Icon from "../../components/custom-widgets/icon";
import NotificationAlert from "../../components/notifications/notification-alert";

//helper functions
import showMktoForm from "../../helpers/showMktoForm";

const CommercialBanking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/commercial-banking/hero-commercial-banking-12282022-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "commercial-banking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Women smiling in business meeting",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Commercial Banking"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Contact a WaFd Commercial Banker"
          }
        },
        {
          id: 3,
          button: {
            id: "commercial-banking-hero-cta",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "Commercial Banking Services",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Commercial Banking Services"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "WaFd Bank's commercial banking services let you run your business while easing your banking burden. See what we have to offer and sign up today."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "keywords",
        content:
          "Commercial Banking, Business Banking, Government Banking, Treasury Prime, lending, real estate, security and fraud, government, equipment, financing, payables, receivables, acquistions, industries"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-banking-12282022.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Commercial Banking"
    }
  ];

  const builtForBusinessVideoData = {
    title: "Built for Business video",
    vimeoId: "458726451",
    minHeight: "360px",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  const customerSpotLightVideoData = {
    title: "WaFd Bank Commercial Partner - Boise Mobile Equipment",
    vimeoId: "353632824",
    minHeight: "360px",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  const brianObieVideoData = {
    title: "Brian Obie, President & CEO - Obie Companies",
    vimeoId: "755179665",
    minHeight: "360px",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  const waFdPartnershipVideoData = {
    title: "How WaFd Bank Transformed Tri-Cities Toyota's Business Banking Experience",
    vimeoId: "841961760",
    minHeight: "360px",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  const iconBlocks = [
    {
      id: "commercial-lending-link",
      title: "Commercial Lending",
      iconSrc: "/images/icons/commercial-lending-icon.svg",
      href: "/commercial-banking/commercial-lending"
    },
    {
      id: "commercial-real-estate-link",
      title: "Commercial Real Estate",
      iconSrc: "/images/icons/commercial-real-estate-icon.svg",
      href: "/commercial-banking/commercial-real-estate-loans"
    },
    {
      id: "treasury-management-link",
      title: "Treasury Management",
      iconSrc: "/images/icons/treasury-management-icon.svg",
      href: "/commercial-banking/treasury-management-services"
    },
    {
      id: "specialized-industries-link",
      title: "Specialized Industries",
      iconSrc: "/images/icons/specialized-industries-icon.svg",
      href: "/commercial-banking/specialized-industries"
    }
  ];

  const commercialBankingTeam = [
    {
      text: "Arizona Commercial Banking",
      url: "/commercial-banking/arizona",
      id: "arizona-commercial-banking-link"
    },
    {
      text: "California Commercial Banking",
      url: "/commercial-banking/california",
      id: "california-commercial-banking-link"
    },
    {
      text: "Idaho Commercial Banking",
      url: "/commercial-banking/idaho",
      id: "idaho-commercial-banking-link"
    },
    {
      text: "Nevada Commercial Banking",
      url: "/commercial-banking/nevada",
      id: "nevada-commercial-banking-link"
    },
    {
      text: "New Mexico Commercial Banking",
      url: "/commercial-banking/new-mexico",
      id: "new-mexico-commercial-banking-link"
    },
    {
      text: "Oregon Commercial Banking",
      url: "/commercial-banking/oregon",
      id: "oregon-commercial-banking-link"
    },
    {
      text: "Utah Commercial Banking",
      url: "/commercial-banking/utah",
      id: "utah-commercial-banking-link"
    },
    {
      text: "Texas Commercial Banking",
      url: "/commercial-banking/texas",
      id: "texas-commercial-banking-link"
    },
    {
      text: "Washington Commercial Banking",
      url: "/commercial-banking/washington",
      id: "washington-commercial-banking-link"
    }
  ];

  const getAlertBody = () => {
    return (
      <Link id="libor-transition-link" to="/commercial-banking/libor-transition" className="text-decoration-none">
        What You Need to Know About LIBOR Transition to{" "}
        <nobr>
          SOFR
          <Icon name="arrow-right" lib="far" class="ml-1" />
        </nobr>
      </Link>
    );
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container pb-0">
        <NotificationAlert type="primary" id="libor-notification-alert" bodyContent={getAlertBody()} />
        <div className="row">
          <div className="col-md-6">
            <h1>A Better Way of Banking</h1>
            <h4>
              While we offer a variety of products and services, our commitment to personal service is unmatched in the
              industry. Our Commercial Bankers have the experience and expertise to serve as trusted partners for
              businesses in a variety of industries. From Construction to Healthcare or Government to
              Apartments&mdash;we have a partner for you.
            </h4>
          </div>
          <div className="col-md-6">
            <VimeoVideoFrame {...builtForBusinessVideoData} />
          </div>
        </div>
      </section>

      <section className="pb-0 container" id="local-commercial-team">
        <div className="row">
          <div className="col-md-6 mb-4">
            <h4 className="text-success font-weight-bold">Meet Your Local Commercial Banking Team</h4>
            {commercialBankingTeam.map((data, index) => (
              <div className="mb-3" key={index}>
                <Link to={data.url} id={data.id} className="text-decoration-none">
                  {data.text}
                  <Icon name="arrow-right" lib="far" class="ml-2" />
                </Link>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <div className="row">
              {iconBlocks.map((data, index) => (
                <div
                  className="col-sm-6 text-center mb-4 d-flex d-sm-block align-items-center"
                  key={index}
                  id={`commercial-icon-block-${index + 1}`}
                >
                  <Link id={data.id} className="font-weight-bold text-decoration-none" to={data.href}>
                    <div className="mb-sm-3 col-auto">
                      <img src={data.iconSrc} alt="" />
                    </div>
                    {data.title} <Icon name="arrow-right" lib="far" class="ml-2" />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-info text-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4 h-100">
              <VimeoVideoFrame {...brianObieVideoData} />
              <div className="mt-3">
                <strong>Brian Obie</strong>
                <br />
                President & CEO - Obie Companies
              </div>
            </div>
            <div className="col-sm-6 col-md-4 h-100">
              <VimeoVideoFrame {...waFdPartnershipVideoData} />
              <div className="mt-3">
                <strong>Peter Chung</strong>
                <br />
                President - Toyota of Tri-Cities
              </div>
            </div>
            <div className="col-sm-6 col-md-4 h-100">
              <VimeoVideoFrame {...customerSpotLightVideoData} />
              <div className="mt-3">
                <strong>Chad Moffat President</strong>
                <br />
                Boise Mobile Equipment
              </div>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default CommercialBanking;
